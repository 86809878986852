/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; margin: 0;}
body { 
    margin: 0; 
    font-family: Roboto, "Helvetica Neue", sans-serif; 
}


.amount{
    font-size: large;
    font-weight: bolder;
}

.profit{
    color: green;
}

.loss{
    color: red;
}


/* cards for calc pages */
.input-field{
    width: 100%;
}

.card-header{
    font-size: 120%;
    font-weight: bolder;
}

.card-body{
    background-color: #f0f0f5;
}

.display-result{
    background-color: black;
    color: rgb(223, 221, 221);
}

.display-result-dialog{
    background-color: rgb(223, 221, 221);
    color: black;
}


.display-result-text{
    color: gray;
    font-size: 80%;
}

.display-result-number{
    font-size: 100%;
}

.final-display{
    background-color: black;
    border-bottom-style: solid;
    /* border-bottom:; */
    border-bottom-color: gray;
}

.final-display-text{color: #a6a2aa;}
.final-display-number{color: #ffffff; font-size: 120%;} 


@media(min-width: 1300px){
    .card{
        max-width: 1300px;
        margin-left: 15%;
        margin-right: 15%;
    }
    
}
/* cards for calc pages */


/* buttons */
.btn-delete{
    background-color: #ff0000;
}

.btn-success{
    background-color: #009933;
}
/* buttons */